import UserGroupService from "../../../api-service/user-group-service/UserGroupService";

export const appAlreadyExistsForUserGroup = async (
    appId: string,
    groupId: string,
  ) => {
    const appsByUserGroupId: any = await UserGroupService.getAppsOfUserGroup(
      groupId,
    );
    const allAppsOfUserGroup = Object.keys(
      appsByUserGroupId?.connectionPermissions ?? {}
    );
    return allAppsOfUserGroup.indexOf(appId) !== -1;
  };