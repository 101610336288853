import UserGroupService from '../../../api-service/user-group-service/UserGroupService';
import AppService, {
  IAddOrRemoveAppsToUserRequestBody,
  IAppsOfUserResponse,
} from '../../../api-service/app-service/AppService';
import { IUserGroupsAndAppsTableRow } from '../../../core-utils/constants';
import { createOperationRequest } from '../../../core-utils/Helper/helper';
import { OperationTypes } from '../../../core-utils/Interfaces/entityInterfaces';
import UserService, {
  IListUsersPoliciesResponse,
} from '../../../api-service/user-service/UserService';
import { IPoliciesTableRowProps } from '../../molecules/PoliciesTableRow';
import {
  IAddPoliciesToGroupOrUserRequestBody,
  IListPolicyResponse,
} from '../../../api-service/policy-service/PolicyService';
import { getAppById } from '../AppDetailsPage/index.hook';
import ApplicationService from 'src/api-service/application-service/ApplicationService';

export const constructListApps = async (
  effectiveAppsOfUserResponse: IAppsOfUserResponse,
  userOrGroupId: any,
  target: 'usergroup' | 'user',
  getAppsOfUserOrUserGroup: any,
  userOrGroupName: string,
  popUpApp: any,
) => {
  const effectiveAppsIds = Object.keys(
    effectiveAppsOfUserResponse?.connectionPermissions ?? {}
  );
  const appsOfUser: IUserGroupsAndAppsTableRow[] = [];

  if (target === 'user') {
    const appsOfUserResponse = await UserService.getAppsOfUser(userOrGroupId);
    const idsOfAppsOfUser = Object.keys(
      appsOfUserResponse?.connectionPermissions ?? {}
    );
    const idsOfAppsOfUserGroupsOfUser = effectiveAppsIds.filter(
      (id) => !idsOfAppsOfUser.includes(id),
    );

    const oneDimDict: any = await UserService.getUserGroupsAppsOfUser(
      userOrGroupId,
    );
    const oneDimApps = Object.keys(oneDimDict);
    idsOfAppsOfUser.forEach((appId) => {
      if (oneDimApps.includes(appId)) {
        idsOfAppsOfUserGroupsOfUser.push(appId);
      }
    });

    const filteredApps = idsOfAppsOfUser.filter(
      (id) => !oneDimApps.includes(id),
    );
    const promisesOfAppsOfUser = getPromisesOfApps(
      filteredApps,
      true,
      userOrGroupId,
      target,
      getAppsOfUserOrUserGroup,
      userOrGroupName,
      popUpApp,
      appsOfUser,
    );

    const promisesOfAppsOfUserGroupOfUser = getPromisesOfApps(
      idsOfAppsOfUserGroupsOfUser,
      false,
      userOrGroupId,
      target,
      getAppsOfUserOrUserGroup,
      userOrGroupName,
      popUpApp,
      appsOfUser,
      oneDimDict,
    );
    await Promise.all(promisesOfAppsOfUser);
    await Promise.all(promisesOfAppsOfUserGroupOfUser);
  } else {
    const promisesOfAppsOfUserGroups = getPromisesOfApps(
      effectiveAppsIds,
      true,
      userOrGroupId,
      target,
      getAppsOfUserOrUserGroup,
      userOrGroupName,
      popUpApp,
      appsOfUser,
    );
    await Promise.all(promisesOfAppsOfUserGroups);
  }
  appsOfUser.sort((a, b) => a.name.localeCompare(b.name));
  return appsOfUser;
};

export const getPromisesOfApps = (
  ids: string[],
  showClose: boolean,
  userOrGroupId: any,
  target: 'usergroup' | 'user',
  getAppsOfUserOrUserGroup: any,
  userOrGroupName: string,
  popUpApp: any,
  appsOfUser: IUserGroupsAndAppsTableRow[],
  usergroupsOfAppsDict?: any,
) => {
  return ids.map(async (appId: string) => {
    const appResponse: any = await AppService.getAppById(appId);
    const convertedAppResponse: IUserGroupsAndAppsTableRow = {
      logo: appResponse.attributes.thumbnail,
      name: appResponse.attributes?.displayName || appResponse.name,
      underTabItems: true,
      target: 'app',
      handleTableRowClick: () => {
        popUpApp(appId);
      },
      handleClickMore: () => {
        removeAppFromUserOrUserGroup(
          appId,
          userOrGroupId,
          target,
          getAppsOfUserOrUserGroup,
        );
      },
      tabItemName: userOrGroupName,
      showClose: showClose,
      context: target,
      usergroupsOfApps: usergroupsOfAppsDict
        ? usergroupsOfAppsDict[appId]
        : null,
    };
    if (appResponse.attributes.displayFlag === 'true') {
      appsOfUser.push(convertedAppResponse);
    }
  });
};

export const removeAppFromUserOrUserGroup = async (
  appId: string,
  userOrGroupId: any,
  target: 'usergroup' | 'user',
  getAppsOfUserOrUserGroup: any,
  getUsersOfApp?: any,
) => {
  const requestBody: IAddOrRemoveAppsToUserRequestBody[] = [
    createOperationRequest(
      OperationTypes.remove,
      'READ',
      `/connectionPermissions/${appId}`,
    ),
  ];
  try {
    if (target === 'user') {
      await UserService.removeAppsFromUser(userOrGroupId, requestBody);
      getAppById(appId).then((response) => {
        getAppsOfUserOrUserGroup(
          response.userMembers,
          response.name,
          response.userGroupMembers,
        );
      });
    } else {
      await UserGroupService.removeAppsFromUserGroup(
        userOrGroupId,
        requestBody,
      );
      getAppById(appId).then((response) => {
        getAppsOfUserOrUserGroup(response.userGroupMembers, response.name);
        getUsersOfApp &&
          getUsersOfApp(
            response.userMembers,
            response.name,
            response.userGroupMembers,
          );
      });
    }
  } catch (error) {
    console.warn(error);
  } finally {
    //
  }
};

const getUserGroups = async (username: string) => {
  const groupResponse = await UserGroupService.getUserGroupsForUser(username); //NOSONAR
  return groupResponse;
};

const getUserGroupObject = async (
  groupname: string,
  username: string,
  name: string,
  getUserGroupsForUser: any,
  popUpUserGroup: any,
  getAppsOfUser: any,
  getPoliciesOfUser?: any,
) => {
  const userlist: any = await UserGroupService.getUsersInUserGroup(groupname);
  return {
    name: groupname,
    userLength: userlist.length,
    underTabItems: true,
    target: 'usergroup',
    handleClickMore: () => {
      removeUserGroupFromUser(
        groupname,
        username,
        getUserGroupsForUser,
        getAppsOfUser,
        getPoliciesOfUser,
      );
    },
    handleTableRowClick: () => {
      popUpUserGroup(groupname, userlist.length);
    },
    tabItemName: name ? name : username,
    showClose: true,
  };
};

const removeUserGroupFromUser = async (
  groupId: string,
  username: string,
  getUserGroupsForUser: any,
  getAppsOfUser: any,
  getPoliciesOfUser?: any,
) => {
  const requestBody = [createOperationRequest(OperationTypes.remove, groupId)];
  try {
    await UserService.removeGroupsFromUser(username, requestBody);
  } catch (error) {
    console.warn(error);
  } finally {
    getUserGroupsForUser();
    getPoliciesOfUser && getPoliciesOfUser();
    getAppsOfUser();
  }
};

const constructTableRows = async (
  groupResponse: any,
  username: string,
  tabItemName: string,
  getUserGroupsForUser: any,
  popUpUserGroup: any,
  getAppsOfUser: any,
  getPoliciesOfUser?: any,
) => {
  const constructedRows = await Promise.all(
    groupResponse.map((groupId: any) =>
      getUserGroupObject(
        groupId,
        username,
        tabItemName,
        getUserGroupsForUser,
        popUpUserGroup,
        getAppsOfUser,
        getPoliciesOfUser,
      ),
    ),
  );
  constructedRows.sort((a: any, b: any) => a.name.localeCompare(b.name));
  return constructedRows;
};

export const getUserGroupsFormatted = async (
  username: string,
  tabItemName: string,
  getUserGroupsForUser: any,
  popUpUserGroup: any,
  getAppsOfUser: any,
  getPoliciesOfUser?: any,
) => {
  const groupResponse = await getUserGroups(username);
  const constructed = await constructTableRows(
    groupResponse,
    username,
    tabItemName,
    getUserGroupsForUser,
    popUpUserGroup,
    getAppsOfUser,
    getPoliciesOfUser,
  );
  return constructed;
};

export const getUserById = async (username: string) => {
  const { username: email, attributes } = await UserService.getUserById(username);
  const { systemPermissions } = await UserService.getEffectiveAppsOfUser(
    username,
  );
  const role = attributes.jobRole || ''; 
  const convertedUserDetails = {
    id: username,
    name: attributes?.['guac-full-name'],
    email: email || attributes?.['guac-email-address'],
    jobTitle: attributes?.['guac-organizational-role'],
    password: '',
    // userStatus: attributes?.disabled ? 'InActive' : 'Active',
    // TODO: Hardcoding source because API does not provide it.
    // Currently this is the only mechanism supported
    source: attributes?.['sonet-source'] ?? '',
    createdBy: attributes?.['createdBy'] ?? '',
    createdOn: attributes?.['createdOn'] ?? '',
    description: attributes?.['description'] ?? '',
    role: role,
  };
  return convertedUserDetails;
};

export const getPolicyTableRowsForUsers = (
  listPolicies: IListPolicyResponse[],
  userName: string,
  userId: string,
  getPoliciesOfUser: any,
) => {
  const resultPolicyTableRows: IPoliciesTableRowProps[] = [];
  listPolicies.forEach((policy) => {
    resultPolicyTableRows.push({
      contextName: userName,
      id: policy.id,
      policyName: policy.policyName,
      createdBy: policy.createdBy,
      showClose: policy.showClose,
      userGroups: policy.usergroupsOfPolicy ? policy.usergroupsOfPolicy : [],
      dateTime: policy.lastPolicyHitTime ?? '',
      applications: policy.applications ? policy.applications : [],
      handleRemove: () => {
        removePolicyForUser(userId, policy.id, getPoliciesOfUser);
      },
      status:
        policy.policyStatus.toLowerCase() === 'active' ? 'Active' : 'Inactive',
      underTab: 'users',
      hideStatus: true,
      disablePolicy: policy.disablePolicy,
    });
  });
  resultPolicyTableRows.sort(function (a, b) {
    if (a.policyName === b.policyName) {
      return 0;
    } else if (a.policyName === null) {
      return 1;
    } else if (b.policyName === null) {
      return -1;
    } else {
      return a.policyName.toLowerCase() < b.policyName.toLowerCase() ? -1 : 1;
    }
  });
  return resultPolicyTableRows;
};

export const removePolicyForUser = async (
  userId: string,
  policyId: string | number,
  getPoliciesOfUser: any,
) => {
  const requestBody: IAddPoliciesToGroupOrUserRequestBody[] = [
    createOperationRequest(OperationTypes.remove, userId, '/'),
  ];
  try {
    await UserService.removePolicyFromUser(policyId, requestBody);
  } catch (error) {
    console.warn(error);
  } finally {
    getPoliciesOfUser();
  }
};

export const removeCreds = async (
  appId: string,
  uId: string,
  getUsersOfUserGroup: any,
) => {
  try {
    await ApplicationService.deleteCredentials(appId, uId);
    getAppById(appId).then((response) => {
      getUsersOfUserGroup(
        response.userMembers,
        response.name,
        response.userGroupMembers,
      );
    });
  } catch (error) {
    console.warn(error);
  } finally {
    //
  }
};

export const refreshTabdata = async (
  appId: string,
  uId: string,
  getUsersOfUserGroup: any,
) => {
  try {
    getAppById(appId).then((response) => {
      getUsersOfUserGroup(
        response.userMembers,
        response.name,
        response.userGroupMembers,
      );
    });
  } catch (error) {
    console.warn(error);
  } finally {
    //
  }
};
