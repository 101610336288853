import UserService from '../../../api-service/user-service/UserService';
import { IUserGroupsAndAppsTableRow } from '../../../core-utils/constants';
import { ApplicationSelectCardProps } from '../../molecules/ApplicationSelectCard';

export const constructAppCards = (apps: any, appDetails: any) => {
  const appCardsData: ApplicationSelectCardProps[] = [];
  const requiredAppNames: string[] = [];
  appDetails.map((app: any) => {
    requiredAppNames.push(app.name);
  });
  apps.forEach((app: any) => {
    if (app.attributes.displayFlag === 'true') {
      appCardsData.push({
        id: app.identifier,
        logo: app.attributes.thumbnail,
        heading: app.attributes?.displayName || app.name,
        //TODO: content: app.attributes.content ----- change this when API includes a field named content in it.
        content: app.attributes.content ?? '',
        disable: requiredAppNames.includes(app.name),
      });
    }
  });

  return appCardsData;
};

export const appAlreadyExistsForUser = async (
  appId: string,
  userId: string,
) => {
  const appsByUserId: any = await UserService.getEffectiveAppsOfUser(userId);
  const allAppsOfUser = Object.keys(appsByUserId?.connectionPermissions ?? {});
  return allAppsOfUser.indexOf(appId) !== -1;
};
